import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Grid, Image, Loader } from '@mantine/core';
import { FormLandingOrder, FormOrder } from '../components/Sections';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { IconPlus } from '@tabler/icons-react';
import { useScrollIntoView, useWindowScroll } from '@mantine/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useStore from '../store/useStore';
import parse from 'html-react-parser';
import classes from './../styles/LandingProduct.module.css';


export function LandingProduct () {
    let { id } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const [product, setProduct] = useState<any>(null)
    const [displayButton, setDisplayButton] = useState(true)
    const [scroll, scrollTo] = useWindowScroll();
    const { scrollIntoView, targetRef, } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({
        onScrollFinish: () => {
            setDisplayButton(false)
        }
    });
    const [imageLoaded, setImageLoaded] = useState(false);
    const [newDescription, setNewDescription] = useState<any>(null);
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/landing-product/${id}`)
        .then(({data}) => {
            setProduct(data)
        })
        .catch((error) => console.log(error));
    }, [id])

    useEffect(() => {
        if (scroll.y <= 100) {
            setDisplayButton(true)
        }
    }, [scroll])

    useEffect(() => {
        if (product && "placeForm" in product) {
            moveElement(product?.placeForm);
        }
    }, [product])

    function moveElement(targetSelector: number) {
        const element = document.querySelector("#formContainer");
        const images = document.querySelectorAll("img");
        const target = images[targetSelector-1]

        if (element && target) {
            target.insertAdjacentElement('afterend', element);
        }
    }

    return (
        <>
            {/* <LazyLoadImage
                src={`${process.env.REACT_APP_API_URL_IMAGES}/${product?.image}.webp`}
                alt={""}
                height={"auto"}
                width={"100%"}
                effect='blur'
                threshold={100}
                delayTime={0}
                style={{cursor: 'pointer'}}
                onClick={() => scrollIntoView()}
                onLoad={() => setImageLoaded(true)}
                placeholderSrc='/loading.gif'
            /> */}
            {/*<Image*/}
            {/*    src={`${process.env.REACT_APP_API_URL_IMAGES}/${product?.image}.webp`}*/}
            {/*    alt={""}*/}
            {/*    height={"auto"}*/}
            {/*    width={"100%"}*/}
            {/*    style={{cursor: 'pointer'}}*/}
            {/*    onClick={() => scrollIntoView()}*/}
            {/*    onLoad={() => setImageLoaded(true)}*/}
            {/*/>*/}
            <Box
                mt={30} className={classes.boxDescription}
                dangerouslySetInnerHTML={{__html: product?.description}}
            />

            <Container id={"formContainer"} size={'lg'} my={100}>
                <Grid gutter="xl" justify='center'>
                    <Grid.Col span={{ base: 12, sm: 12, md: 10, lg: 8, xl: 8 }}>
                        <FormLandingOrder targetRef={targetRef} data={product} />
                    </Grid.Col>
                </Grid>
            </Container>

            {displayButton
                ? <Box style={{position: "fixed", bottom: 20, left: 20}}>
                    <Button className='pulse-button'
                        variant="filled" size={'lg'} color={dataStore?.information?.backgroundColor || "#645cbb"}
                        leftSection={<IconPlus size={18} />}
                        onClick={() => scrollIntoView()}
                    >أطلب الان</Button>
                </Box>
                : null
            }
            
        </>
    );
}